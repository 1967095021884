import * as s from './styles/404.module.scss';
import Layout from '../components/layout';
import React, { ReactElement } from 'react';

export default function NotFound(): ReactElement {
  return (
    <Layout title="Página não encontrada">
      <section className={`u-nav-clear ${s.NotFound_section}`}>
        <h1 className="heading u-margin-bottom">404</h1>
        <h2 className="title">Ops! Não encontramos a página solicitada!</h2>
      </section>
    </Layout>
  )
}
